<template>
  <div class="home">
    <div class="middle1" v-if="show">
      <el-select v-model="value" placeholder="请选择" @change="getSchool">
        <el-option v-for="item in options" :key="item.id" :label="item.libName
          " :value="item.libMark">
        </el-option>
      </el-select>
    </div>
    <div class="middle" v-else>
      <div class="middle">
        <div class="con">
          <p class="title">登录</p>
          <el-form :model="ruleForm" :rules="rules" style="width: 80%; margin: 30px auto 20px">
            <el-form-item prop="phone">
              <el-input type="phone" v-model="ruleForm.phone" autocomplete="off" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item prop="imgcode" style="position: relative">
              <el-input v-model="ruleForm.imgcode" autocomplete="off" placeholder="请输入图形验证码"></el-input>
              <span v-if="isshow == 1" class="getcode" @click="dianji">点击获取验证码</span>
              <span v-if="isshow == 2" class="getcode" @click="getimgcode"><img :src="src" alt="" /></span>
            </el-form-item>
            <!-- <p class="link">
              <span class="left">注册</span>
              <span class="right">忘记密码？</span>
            </p> -->
            <!-- <el-checkbox v-model="ruleForm.checked" style="margin: 20px 0px 0px;"
            >我已阅读并接受相关条款</el-checkbox
          > -->
            <p class="next" @click="login">登录</p>
          </el-form>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import API from "../api/api_user";
export default {
  name: "home",
  data() {
    return {
      options: [],
      show: true,
      value: '',
      isshow: 1,
      btnTitle: "获取验证码",
      src: "",
      ruleForm: {
        phone: "",
        password: "",
        imgcode: "",
        checked: false,
      },
      logintoken: 1,
      Randnum: "",
      weChat: "",
      frameShow: false,
      wxCode: "",
      code: "",
      rules: {
        imgcode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ]
      }

    };
  },
  created() {
    this.getList();

  },
  mounted() {
    // this.logintoken = localStorage.getItem("tokenlogin");
    // //在页面的mounted或者created生命周期里面获取就可以
    // let code = this.getQueryString("code");
    // this.code = code;
    // if (this.logintoken !== null) {
    //   this.bound()
    // } else {
    //   if (code) {
    //     API.wxLogin({ code: code }).then((res) => {
    //       if (res.code == 0) {
    //         localStorage.setItem("tokenlogin", res.token);
    //         this.$router.push({ path: "./Retrievalsetting" });
    //       }
    //     });
    //   }
    // }
  },
  methods: {
    getSchool(e) {
      console.log(e, "ceshishuju")
      localStorage.setItem("urlSchool", e)
      this.show = false
    },
    getList() {
      API.getList().then((res) => {
        console.log(res, "ceshishuju")
        this.options = res.data
      });
    },//这是方法
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = r[2];
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },
    //DES加密 Pkcs7填充方式
    encryptByDES(message, key) {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
    //DES解密
    decryptByDES(ciphertext, key) {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      // direct decrypt ciphertext
      const decrypted = CryptoJS.DES.decrypt(
        {
          ciphertext: CryptoJS.enc.Base64.parse(ciphertext),
        },
        keyHex,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return decrypted.toString(CryptoJS.enc.Utf8);
    },
    // userLogin
    // $router.push({ path: '/' })
    dianji() {
      // this.getList();
    },
    //图片验证吗
    getimgcode() {
      var num = Math.ceil(Math.random() * 10); //生成一个随机数（防止缓存）
      this.src = Env.baseURL + "api/user/getCaptcha?" + num;
    },
    handleAddBook() {

    },
    bound() {
      API.bound({ code: this.code }).then((res) => {
        this.$message(res.msg || "绑定成功");
        this.$router.push({ path: "./Retrievalsetting" });
      });
    },
    login() {
      if (this.ruleForm.phone == "" || this.ruleForm.password == "") {
        this.$message.error("错了哦，请先将内容填写完整");
      } else if (this.ruleForm.imgcode == "") {
        this.$message.error("请填写图形验证码");
      } else {
        API.userLogin({
          username: this.ruleForm.phone.replace(/\s/g, ""),
          password: this.ruleForm.password.replace(/\s/g, ""),
          code: this.ruleForm.imgcode.replace(/\s/g, ""),
        }).then((resToken) => {
          if (resToken.code == 0) {
            localStorage.setItem("tokenlogin", resToken.token);
            this.bound();
          } else {
            this.$message.error(resToken.msg);
          }
        });
      }
    },
    basfunction() {
      var Base64;
      if (window.atob) {
        Base64 = window.Base64 || {
          encode: function (input) {
            return window.b64EncodeUnicode(input);
          },
          decode: function (input) {
            return window.b64DecodeUnicode(input);
          },
        };
      } else {
        Base64 = window.Base64 || {
          /* private property*/
          _keyStr:
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
          /* public method for encoding */
          encode: function (input) {
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;
            input = Base64._utf8_encode(input);
            while (i < input.length) {
              chr1 = input.charCodeAt(i++);
              chr2 = input.charCodeAt(i++);
              chr3 = input.charCodeAt(i++);
              enc1 = chr1 >> 2;
              enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
              enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
              enc4 = chr3 & 63;
              if (isNaN(chr2)) {
                enc3 = enc4 = 64;
              } else if (isNaN(chr3)) {
                enc4 = 64;
              }
              output =
                output +
                Base64._keyStr.charAt(enc1) +
                Base64._keyStr.charAt(enc2) +
                Base64._keyStr.charAt(enc3) +
                Base64._keyStr.charAt(enc4);
            }
            return output;
          },
          /* public method for decoding */
          decode: function (input) {
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
            while (i < input.length) {
              enc1 = Base64._keyStr.indexOf(input.charAt(i++));
              enc2 = Base64._keyStr.indexOf(input.charAt(i++));
              enc3 = Base64._keyStr.indexOf(input.charAt(i++));
              enc4 = Base64._keyStr.indexOf(input.charAt(i++));
              chr1 = (enc1 << 2) | (enc2 >> 4);
              chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
              chr3 = ((enc3 & 3) << 6) | enc4;

              output = output + String.fromCharCode(chr1);

              if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
              }
              if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
              }
            }
            output = Base64._utf8_decode(output);
            return output;
          },
          /* private method for UTF-8 encoding */
          _utf8_encode: function (string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";
            for (var n = 0; n < string.length; n++) {
              var c = string.charCodeAt(n);
              if (c < 128) {
                utftext += String.fromCharCode(c);
              } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
              } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
              }
            }
            return utftext;
          },
          /* private method for UTF-8 decoding */
          _utf8_decode: function (utftext) {
            var string = "";
            var i = 0;
            var c = (c1 = c2 = 0);
            while (i < utftext.length) {
              c = utftext.charCodeAt(i);
              if (c < 128) {
                string += String.fromCharCode(c);
                i++;
              } else if (c > 191 && c < 224) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
              } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(
                  ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
                );
                i += 3;
              }
            }
            return string;
          },
        };
      }
      window.Base64 = Base64;
    }



  }
};
</script>
<style>
.home {
  width: 100%;
  height: auto;

}

.middle1 {
  width: 100%;
  display: flex;
}

.middle1 .el-select {
  margin: 40px auto;
}

.middle {
  margin: 0 auto;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: calc(40vh - 200px);
}

.middle .con {
  /* width: calc(100% -20px); */
  height: auto;
  /* padding: 100px 75px; */
}

.middle .con .title {
  text-align: center;
  font-size: 22px;
}

.middle .con .title .right {
  letter-spacing: 0px;
  font-size: 14px;
  /* float: right; */
  line-height: 40px;
  color: #908b8b;
  font-weight: 600;
  margin-left: 25px;
}

.el-step__icon.is-text {
  background-color: #e94f35;
}

.middle .con .link {
  width: 100%;
  display: flex;
  margin: 0px;
}

.middle .con .link .left {
  width: 50%;
  height: auto;
  font-size: 12px;
  color: #2882fe;
  text-align: left;
}

.middle .con .link .right {
  width: 50%;
  height: auto;
  font-size: 12px;
  color: #999999;
  text-align: right;
}

.middle .con .next {
  width: 100%;
  height: 40px;
  color: #fff;
  background: #2882fe;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.middle .con .nexts {
  width: 100%;
  height: 40px;
  color: #ccc;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.middle .con .nexs {
  background-color: #ae703e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  color: #ccc;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.middle .con .nexs img {
  width: 24px;
  height: 24px;
}

.middle .con .getcode {
  display: inline-block;
  width: 95px;
  color: #ccc;
  font-size: 10px;
  position: absolute;
  right: 2px;
  top: 0px;
  text-align: center;
  border: none;
  background-color: #fff;
  outline: none;
  height: 0px;
}

.middle .con .getcode::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 21px;
  background-color: #ccc;
  position: absolute;
  top: 10px;
  left: 0px;
}

.middle .con .getcode img {
  width: 100%;
  height: 100%;
}

::v-deep .loginbutton .jiebang {
  width: 130px;
  height: 45px;
  background-color: #c3c3c3;
  border-radius: 5px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  color: #ffffff;
}

::v-deep .loginbutton .bang {
  width: 130px;
  height: 45px;
  background-color: #ac7345;
  border-radius: 5px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  color: #ffffff;
}

.logincon {
  height: auto;
  margin: 0 auto;
}

.loginbutton {
  width: 56%;
  margin: 146px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loginafter {
  width: 56%;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logincon .title {
  text-align: center;
  font-size: 22px;
}
</style>
